import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

import SEO from 'components/SEO';
import Layout from 'components/Layout';
import Content from 'components/Content';
import Link, { LinkVariant } from 'components/Link';
import SectionTitle from 'components/SectionTitle';
import Note, { NoteVariant } from 'components/Note';
import { LINKS } from 'src/utils/constants';
import Accordion, { AccordionVariant } from 'components/Accordion';
import FixedWidthCol from 'components/FixedWidthCol';
import { Hero, InfoSection } from './product-information-import';
import { HeadingScope } from 'components/Heading';

const EPIPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Layout footerHasMargin>
      <Hero
        image={
          <StaticImage
            src="../assets/images/features/edit-product-information/epi-main.jpg"
            alt={t('epi_heading')}
            layout="fullWidth"
          />
        }
        title={t('epi_heading')}
        text={t('epi_text')}
      />
      <HeadingScope>
        <Content>
          <FixedWidthCol>
            <SectionTitle subtitle={t('epi_benefits_subtitle')}>
              <Trans i18nKey="epi_benefits_title" />
            </SectionTitle>
            <Note variant={NoteVariant.ALFA}>
              <p>
                <Trans i18nKey="epi_benefits_note" />
              </p>
            </Note>
            <Link url={LINKS.REGISTER} variant={LinkVariant.BRAVO}>
              <Trans i18nKey="common_try_free_of_charge" />
            </Link>
          </FixedWidthCol>
          <SectionTitle>
            <Trans i18nKey="epi_how_title" />
          </SectionTitle>
          <InfoSection>
            <StaticImage
              src="../assets/images/features/edit-product-information/epi-how.jpg"
              alt={t('epi_how_title')}
              width={620}
              height={620}
              quality={100}
            />
            <Accordion
              firstActive
              variant={AccordionVariant.BRAVO}
              items={[
                {
                  title: t('epi_how_1_title'),
                  content: (
                    <p>
                      <Trans i18nKey="epi_how_1_text" />
                    </p>
                  ),
                },
                {
                  title: t('epi_how_2_title'),
                  content: (
                    <>
                      <p>
                        <strong>
                          <Trans i18nKey="epi_how_2_text_1" />.{' '}
                        </strong>
                        <Trans i18nKey="epi_how_2_text_2" />
                      </p>
                      <p>
                        <strong>
                          <Trans i18nKey="epi_how_2_text_3" />.{' '}
                        </strong>
                        <Trans i18nKey="epi_how_2_text_4" />
                      </p>
                      <p>
                        <strong>
                          <Trans i18nKey="epi_how_2_text_5" />.{' '}
                        </strong>
                        <Trans i18nKey="epi_how_2_text_6" />
                      </p>
                    </>
                  ),
                },
                {
                  title: t('epi_how_3_title'),
                  content: (
                    <p>
                      <Trans i18nKey="epi_how_3_text" />
                    </p>
                  ),
                },
                {
                  title: t('epi_how_4_title'),
                  content: (
                    <p>
                      <Trans i18nKey="epi_how_4_text" />
                    </p>
                  ),
                },
              ]}
            />
          </InfoSection>
        </Content>
      </HeadingScope>
    </Layout>
  );
};

export default EPIPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="Edit Product Information | OmniGet"
    description="Easily update and manage product details with OmniGet's intuitive editing tools. Ensure your product information is always accurate and up-to-date."
  />
);
